import React from "react";
import DoublePhone from "./images/doublePhone.webp";
import { useTranslation } from "react-i18next";

import "./About.css";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="about" id="about">
      <div className="container">
        <div className="col-2">
          <h2>{t("About us")}</h2>
          <p>
            {t("A2H Math Solver transforms the way you learn mathematics. Our innovative app harnesses the latest in technology to deliver customized exercises that adapt to your learning pace, generate tailored revision sheets that focus on your specific needs, and allow you to upload your coursework for a completely personalized study experience. Dive into our 'Interactive Learning' feature for dynamic problem-solving sessions and utilize our 'Scan and Solve' technology for instant solutions to complex mathematical questions. Join our thriving community and take advantage of our smart study assistant to navigate through any mathematical challenge with confidence. Experience a unique educational journey tailored just for you with A2H Math Solver."
            )}
          </p>
        </div>
        <img src={DoublePhone} alt="iphone" />
      </div>
    </div>
  );
};

export default About;
