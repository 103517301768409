import React, { Suspense } from "react";
import "./Hero.css";
import phone from "./images/phone.webp";
import buleLogo from "./images/blueLogo.png";
import Apple from "./images/Apple.webp";
import Google from "./images/googleApp.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import useMediaQuery from "@mui/material/useMediaQuery";

import slide1 from "./images/slide1.webp";
import slide2 from "./images/slide2.webp";
import slide3 from "./images/slide3.webp";
import slide4 from "./images/slide4.webp";
import slide5 from "./images/slide5.webp";
import slide6 from "./images/slide6.webp";
import slide7 from "./images/slide7.webp";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import videoFile from "./videos/A2H_Math_Solver.mp4";
import phoneImage from "./images/phoneback.png";

// import "swiper/css";
// import "swiper/css/effect-coverflow";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
const LazyImage = React.lazy(() => import("./lazyImage"));

const Hero = () => {
  const { t } = useTranslation();

  const matchesL = useMediaQuery("(max-width:940px)");
  const matchesM = useMediaQuery("(max-width:675px)");
  const matchesXl = useMediaQuery("(min-width:1500px)");
  console.log(matchesM);
  return (
    <div className="hero">
      <div className="content">
        <div className="top-content">
          <div className="left-content">
            <p>A2H Math Solver</p>

            <p>{t("Your Key to Unlocking the Mysteries of Mathematics")}</p>
            <p>{t("Have you been stuck trying to solve a math problem?")}</p>
            <p>
              {t(
                "Face a math problem with A2H Math Solver, your all-in-one tool. Use 'Scan and Solve' to get solutions from exercise photos, and explore 'Interactive Learning' for personalized practice that adapts exercises based on your progress. Need more help? Our chatbot is ready to answer all your questions in real time. Additionally, 'Revision Sheets' and 'Quizzes' enrich your learning experience, ensuring comprehensive preparation for all your mathematical challenges."
              )}{" "}
            </p>
          </div>
          <div className="right-content">
            <div className="phone-container">
              <Suspense fallback={<ClipLoader color="#000" size={50} />}>
                <video
                  className="background-video"
                  preload="metadata"
                  autoPlay
                  muted 
                  loop
                  controls
                  src={videoFile}
                />
              </Suspense>
              <img src={phoneImage} alt="phone" className="phone-image" />
            </div>
          </div>
        </div>
        <div className="text-logo-container">
          <p>
            {t(
              "Elevate your math skills. Send message 'A2H Math Solver' for immediate mastery of problem-solving, with interactive exercises and customized quizzes."
            )}
          </p>
          <div
            className="logo-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Suspense fallback={<ClipLoader color="#000" size={50} />}>
              <LazyImage
                loading="lazy"
                className="detail-photo"
                src={buleLogo}
                alt="logo"
              ></LazyImage>{" "}
              <div>
                <div className="App-container">
                  <a href="https://apps.apple.com/in/app/a2h-math-solver/id6474076978">
                    <LazyImage className={'Appel'} loading="lazy" src={Apple} alt="Apple" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.mathsolver.a2h&hl=en">
                    <LazyImage className={'Google'} loading="lazy" src={Google} alt="google" />
                  </a>
                </div>
              </div>
            </Suspense>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", width: "100%",justifyContent:'center' }}>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            initialSlide={3}
            slidesPerView={
              matchesL ? "7" : matchesM ? "auto" : matchesXl ? "7" : "7"
            }
            spaceBetween={matchesM ? 0 : 3}
            coverflowEffect={{
              rotate: 2,
              stretch: matchesM ? 200 : matchesL ? 100 :-30,
              depth: 150,
              modifier: 1.5,
              slideShadows: false,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            {[ slide2, slide3,slide4, slide1 ,slide5, slide6, slide7].map(
              (slide, index) => (
                <SwiperSlide key={index}>
                  <Suspense fallback={<ClipLoader color="#000" size={50} />}>
                    <LazyImage src={slide} alt={`slide_image_${index + 1}`} />
                  </Suspense>
                </SwiperSlide>
              )
            )}

            <div className="slider-controler">
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Hero;
