import React, { useState } from "react";
import "./Footer.css";
import logo from "./images/footerLogo.png";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import InputIcon from "./images/inputIcon.png";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <Divider
      variant="middle"
        classeName="big-line"
        sx={{ marginBottom: "30px", background: "#fff",width:'78%' }}
      />
      <div className="container">
        <div className="log-container">
          <img src={logo} alt="logo"></img>
          <div>© A2H Math Solver 2024</div>
        </div>
        <ul className="list-container">
          <li className="nav-item">
            <a href="https://policies.a2h-tech.com">
              {t("Terms and conditions")}
            </a>
          </li>
          <li className="nav-item">
            <a
              href="
https://policies.a2h-tech.com"
            >
              {t("Privacy policy")}
            </a>
          </li>
          <li className="nav-item">
            <a
              href="
https://policies.a2h-tech.com"
            >
              {t("Cookie")}
            </a>
          </li>
        </ul>
        {/* <div className="last-container">
          <p>{t("Subscribe Now to Get Latest Updates")}</p>
          <TextField
            variant="outlined"
            label={t("Enter your email")}
            fullWidth
            sx={{
              marginTop: "10px",
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#FFFFFF",
                borderRadius: "20px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <img
                      src={InputIcon}
                      width={"45rem"}
                      height={"auto"}
                      alt="InputIcon"
                    ></img>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
