import React from "react";

import buleLogo from "./images/blueLogo.png";
import Apple from "./images/Apple.png";
import Google from "./images/googleApp.png";

import "./Testimonials.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoPhone from "./images/logoPhone.png";
import { useTranslation } from "react-i18next";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: "20px",
  marginBottom: "20px",
  // Apply border radius directly to the Accordion
  "&.MuiAccordion-root": {
    borderRadius: "20px 20px 0 0",
    background: "linear-gradient(110.176deg, #083FA1 0%, #28B9D6 100%)",
  },
  "& .MuiAccordionSummary-root": {
    color: "white",
    fontWeight: "500",
    padding: "0 2rem 0 3rem",
    backgroundColor: "linear-gradient(108.697deg, #083FA1 0%, #28B9D6 49%);",
    borderRadius: "20px 20px 0 0",
    height: "70px",
    alignItems: "center",
    fontSize: "1rem",
    border: "none",
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "64px",
  },
  "& .MuiAccordionDetails-root": {
    backgroundColor: "#E0E0E0",

    fontSize: "1rem",
  },
}));

const Testimonials = () => {
  const { t } = useTranslation();

  return (
    <div className="testimonials" id="FAQ">
      <div className="container">
        <div className="accordiant-container">
          <CustomAccordion>
            <AccordionSummary
              className="AccordionSummary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {t("How does the 'Scan and Solve' feature work?")}
            </AccordionSummary>
            <AccordionDetails>
              {t(
                "The 'Scan and Solve' feature allows you to simply take a photo of a math problem, and the app quickly analyzes the image and provides a detailed step-by-step solution. It's perfect for students who need quick answers while studying or doing homework."
              )}
            </AccordionDetails>
          </CustomAccordion>
          <CustomAccordion>
            <AccordionSummary
              className="AccordionSummary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {t(
                "How can the 'Smart Study Assistant' enhance my learning experience?"
              )}
            </AccordionSummary>
            <AccordionDetails>
              {t(
                "The 'Smart Study Assistant' helps you manage your study materials efficiently. You can upload your course materials, and the assistant will suggest relevant exercises, create custom revision sheets, and help you prepare for specific topics. It also answers your course-related questions directly."
              )}
            </AccordionDetails>
          </CustomAccordion>
          <CustomAccordion>
            <AccordionSummary
              className="AccordionSummary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {t(
                "Can I ask specific questions about the courses I have uploaded?"
              )}{" "}
            </AccordionSummary>
            <AccordionDetails>
              {t(
                "Yes, our smart study assistant allows you to ask questions directly related to your course material for personalized responses."
              )}
            </AccordionDetails>
          </CustomAccordion>
          <CustomAccordion>
            <AccordionSummary
              className="AccordionSummary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {t("Can A2H Math Solver help me with any type of math problem?")}
            </AccordionSummary>
            <AccordionDetails>
              {t(
                "Absolutely! From arithmetic to complex geometry, our app provides solutions to a wide range of problems."
              )}
            </AccordionDetails>
          </CustomAccordion>
        </div>
        <div className="FAQ-container">
          <h2>{t("FAQ")}</h2>
          <div className="line"></div>

          <p>
            {t(
              "The 'Scan and Solve' feature allows you to simply take a photo of a math problem, and the app quickly analyzes the image and provides a detailed step-by-step solution. It's perfect for students who need quick answers while studying or doing homework."
            )}
          </p>

          <img className="logo-images" src={buleLogo} alt="logoIimage"></img>
          <div className="App-container">
            <a href="https://apps.apple.com/in/app/a2h-math-solver/id6474076978">
              <img className="Apple" src={Apple} alt="Apple" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.mathsolver.a2h&hl=en">
              <img className="Google" src={Google} alt="google" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
