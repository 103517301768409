import React, { useState } from "react";
import "./Contact.css";
import { Alert, InputAdornment, Snackbar, TextField } from "@mui/material";
import Apple from "./images/Apple.png";
import Google from "./images/googleApp.png";
import { useTranslation } from "react-i18next";

const style = {
  margin: "10px",
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#E6E6E6",
    borderRadius: "10px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

const Demo = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    email: "",
    firstName: "",
    lastname: "",
    phone: "",
    subject: "",
  });

  const [isPending, setIsPending] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const sendEmail = async () => {
    setIsPending(true);
    try {
      const response = await fetch("https://back.a2h-tech.com/api/v1/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Email sent successfully:", result);
      setSnackbarMessage("Email sent successfully!");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Error sending email:", error);
      setSnackbarMessage("Error sending email.");
      setSnackbarSeverity("error");
    } finally {
      setIsPending(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="demo" id="contact">
      <div className="container">
        <h2>{t("Get in touch!")}</h2>
        <p>
          {t(
            "We're here to help! If you have any questions about our app or need assistance, please don't hesitate to reach out. Simply fill out the form below with your details and query, and we will get back to you as soon as possible."
          )}{" "}
        </p>
        <div className="input-container">
          <TextField
            variant="outlined"
            label={t("First Name *")}
            name="firstName"
            fullWidth
            sx={style}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            label={t("Last Name *")}
            name="lastName"
            fullWidth
            sx={style}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            name="email"
            label="Email*"
            type="email"
            fullWidth
            sx={style}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            label={t("Phone *")}
            name="phone"
            fullWidth
            sx={style}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            name="subject"
            label={t("Subject *")}
            fullWidth
            multiline
            rows={4}
            sx={{ ...style, gridColumn: "span 2" }}
            onChange={handleChange}
          />
        </div>
        <button onClick={sendEmail} className="button-send">
          {isPending ? "Sending..." : t("Send message")}
        </button>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
      <div className="bottom-content">
      <p>
  {t("What are you waiting for ?")}<br />
  {t("Download Now !")}
</p>
        <div className="App-container">
          <a href="https://apps.apple.com/in/app/a2h-math-solver/id6474076978">
            <img src={Apple} alt="Apple" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.mathsolver.a2h&hl=en">
            <img src={Google} alt="google" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Demo;
