import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "./images/logo.png";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./Navbar.css";
import { Button, Menu, MenuItem } from "@mui/material";
import i18next from "i18next";
import usa from "./images/usa.svg";
import france from "./images/france.svg";
import spain from "./images/spain.svg";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

export const AppLanguages = [
  { label: "English", code: "en", icon: usa },
  { label: "French", code: "fr", icon: france },
  { label: "Spanish", code: "es", icon: spain },
];

const Navbar = () => {
  const { t } = useTranslation();
  const lang = JSON.parse(localStorage.getItem('lang'));

  const [click, setClick] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [language, setLanguage] = useState(lang ? lang : AppLanguages[0]);
  const [activeNavItem, setActiveNavItem] = useState("Home"); // State to track active nav item

  const handleClick = () => setClick(!click);
  const open = Boolean(anchorEl);

  const closeMenu = () => setClick(false);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem("lang", JSON.stringify(lang));
    i18next.changeLanguage(lang.code);
  };

  const handleNavItemClick = (item) => {
    setActiveNavItem(item);
    closeMenu(); // Close the menu on click
  };
  return (
    <div className="header">
      <nav className="navbar">
        <a href="/" className="logo">
          <img className={"home-logo"} src={logo} alt="logo" />
        </a>
        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes size={30} style={{ color: "#ffffff" }} />
          ) : (
            <FaBars size={30} style={{ color: "#ffffff" }} />
          )}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <a
              href="/"
              className={activeNavItem === "Home" ? "active" : ""}
              onClick={() => handleNavItemClick("Home")}
            >
              {t("Home")}
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#about"
              className={activeNavItem === "About us" ? "active" : ""}
              onClick={() => handleNavItemClick("About us")}
            >
              {t("About us")}
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#FAQ"
              className={activeNavItem === "FAQ" ? "active" : ""}
              onClick={() => handleNavItemClick("FAQ")}
            >
              {t("FAQ")}
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#contact"
              className={activeNavItem === "Contact us" ? "active" : ""}
              onClick={() => handleNavItemClick("Contact us")}
            >
              {t("Contact us")}
            </a>
          </li>

          <li className="nav-item">
            <LanguageSelector
              languages={AppLanguages}
              language={language}
              setLanguage={setLanguage}
            />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
