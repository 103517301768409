import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Testimonials from "./components/Testimonials";
import Demo from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="background-container">
      <Navbar />
      <Hero />
      <About />
      <div className="background-color">
        <Testimonials />
        <Demo />
        <Footer />
      </div>
    </div>
  );
}

export default App;
